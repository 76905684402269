import React from "react"
import { navigate } from 'gatsby'
import { useLocation } from "@reach/router"

export default function NotFound() {
    const location = useLocation();
    const path = decodeURI(location.pathname)
    React.useEffect(() => {
             if (path === '/イベント/1679/' || path === '/イベント/1679')  navigate('/news/xvips7f2e8');
        else if (path === '/イベント/1686/' || path === '/イベント/1686')  navigate('/news/rn0aeot9g');
        else if (path === '/media/1942/' || path === '/media/1942')     navigate('/news/0ypq87ixr1v');
        else if (path === '/en-news/1819/' || path === '/en-news/1819') navigate('/news/aqz45n3tj');
        else if (path === '/news/10/' || path === '/news/10')           navigate('/news/45vycowkx0');
        else if (path === '/news/52/' || path === '/news/52')           navigate('/news/vmz-exziho0');
        else if (path === '/news/404/' || path === '/news/404')         navigate('/news/hu76yzk0fb');
        else if (path === '/news/406/' || path === '/news/406')         navigate('/news/077zz61k0');
        else if (path === '/news/471/' || path === '/news/471')         navigate('/news/jkx32uz13');
        else if (path === '/news/555/' || path === '/news/555')         navigate('/search');
        else if (path === '/news/562/' || path === '/news/562')         navigate('/news/mmdnhs6e0');
        else if (path === '/news/569/' || path === '/news/569')         navigate('/news/uzjsj5qd3wrc');
        else if (path === '/news/1151/' || path === '/news/1151')       navigate('/search');
        else if (path === '/news/1299/' || path === '/news/1299')       navigate('/news/18b1y0kj0');
        else if (path === '/news/1308/' || path === '/news/1308')       navigate('/search');
        else if (path === '/news/1406/' || path === '/news/1406')       navigate('/news/zln8to0nyn8x');
        else if (path === '/news/1413/' || path === '/news/1413')       navigate('/news/6ytfxdrtf');
        else if (path === '/news/1553/' || path === '/news/1553')       navigate('/news/vgxg2t103x');
        else if (path === '/news/1666/' || path === '/news/1666')       navigate('/news/2rjz3io68j7');
        else if (path === '/news/1708/' || path === '/news/1708')       navigate('/search');
        else if (path === '/news/1727/' || path === '/news/1727')       navigate('/news/d1ylip25sfsn');
        else if (path === '/news/1759/' || path === '/news/1759')       navigate('/news/0io9sja77z');
        else if (path === '/news/1766/' || path === '/news/1766')       navigate('/news/9jne7m4k8');
        else if (path === '/news/1789/' || path === '/news/1789')       navigate('/news/kkzadvbjtr');
        else if (path === '/news/1895/' || path === '/news/1895')       navigate('/news/krzcugaman');
        else if (path === '/news/2036/' || path === '/news/2036')       navigate('/news/u407eyi6l');
        else if (path === '/release/211/' || path === '/release/211')   navigate('/news/toquie31br');
        else if (path === '/release/231/' || path === '/release/231')   navigate('/news/xsf4j8ks0d49');
        else if (path === '/release/261/' || path === '/release/261')   navigate('/news/52mwsq8q2f4a');
        else if (path === '/release/288/' || path === '/release/288')   navigate('/news/30b1mjfqwp');
        else if (path === '/release/320/' || path === '/release/320')   navigate('/news/9ot-9looj7h');
        else if (path === '/release/353/' || path === '/release/353')   navigate('/search');
        else if (path === '/release/371/' || path === '/release/371')   navigate('/news/td8m15z73');
        else if (path === '/release/381/' || path === '/release/381')   navigate('/search');
        else if (path === '/release/431/' || path === '/release/431')   navigate('/news/yqmhn8t1r5f');
        else if (path === '/release/443/' || path === '/release/443')   navigate('/news/f0kyk0xh8');
        else if (path === '/release/458/' || path === '/release/458')   navigate('/news/qj31mjchln9');
        else if (path === '/release/476/' || path === '/release/476')   navigate('/news/e81104d66');
        else if (path === '/release/524/' || path === '/release/524')   navigate('/news/bgye6lyw12');
        else if (path === '/release/578/' || path === '/release/578')   navigate('/news/m801iqurq5u');
        else if (path === '/release/627/' || path === '/release/627')   navigate('/news/0lzy00s33a');
        else if (path === '/release/639/' || path === '/release/639')   navigate('/news/4b0mnlm0smf');
        else if (path === '/release/645/' || path === '/release/645')   navigate('/news/z6f6-vg-thz');
        else if (path === '/release/689/' || path === '/release/689')   navigate('/news/3o666ueug');
        else if (path === '/release/720/' || path === '/release/720')   navigate('/news/9dpdss0jll8');
        else if (path === '/release/831/' || path === '/release/831')   navigate('/news/l3me140bra');
        else if (path === '/release/901/' || path === '/release/901')   navigate('/news/u8n9rgrs-d4');
        else if (path === '/release/96/' || path === '/release/96')     navigate('/news/f8wobtlqqln2');
        else if (path === '/release/960/' || path === '/release/960')   navigate('/news/8sxmzkazj');
        else if (path === '/release/983/' || path === '/release/983')   navigate('/news/7k66791sjn');
        else if (path === '/release/994/' || path === '/release/994')   navigate('/news/t-ffnm95ca');
        else if (path === '/release/1025/' || path === '/release/1025') navigate('/news/dqrsmipiqg');
        else if (path === '/release/1044/' || path === '/release/1044') navigate('/news/frqbk6openvj');
        else if (path === '/release/1077/' || path === '/release/1077') navigate('/news/cc0m73yazf');
        else if (path === '/release/1103/' || path === '/release/1103') navigate('/news/qa04qdx000l5');
        else if (path === '/release/114/' || path === '/release/114')   navigate('/news/42qz5qbaxt');
        else if (path === '/release/1154/' || path === '/release/1154') navigate('/news/y8gt-h08y10');
        else if (path === '/release/1197/' || path === '/release/1197') navigate('/news/987hw0k80d');
        else if (path === '/release/1251/' || path === '/release/1251') navigate('/news/j2eqcpxjvds');
        else if (path === '/release/1275/' || path === '/release/1275') navigate('/news/u8esxin03h6h');
        else if (path === '/release/1317/' || path === '/release/1317') navigate('/news/kh46kc944e-v');
        else if (path === '/release/1353/' || path === '/release/1353') navigate('/news/dn4hn8e1h');
        else if (path === '/release/1400/' || path === '/release/1400') navigate('/news/21gozu-f-0os');
        else if (path === '/release/1403/' || path === '/release/1403') navigate('/news/717tdqac6');
        else if (path === '/release/1409/' || path === '/release/1409') navigate('/news/h0wti0nd09f9');
        else if (path === '/release/1422/' || path === '/release/1422') navigate('/news/7iqzxd85-l');
        else if (path === '/release/143/' || path === '/release/143')   navigate('/news/yr00jjgsbsld');
        else if (path === '/release/1435/' || path === '/release/1435') navigate('/news/g97jftdbgba3');
        else if (path === '/release/1453/' || path === '/release/1453') navigate('/news/0ja-vqra79a');
        else if (path === '/release/1471/' || path === '/release/1471') navigate('/news/quwccdyl1');
        else if (path === '/release/1489/' || path === '/release/1489') navigate('/news/xlwzu5y1uc');
        else if (path === '/release/1505/' || path === '/release/1505') navigate('/news/5cmevrulhbpc');
        else if (path === '/release/1519/' || path === '/release/1519') navigate('/news/58ikv63ab6');
        else if (path === '/release/1524/' || path === '/release/1524') navigate('/news/zrmq7p03-e');
        else if (path === '/release/1545/' || path === '/release/1545') navigate('/news/xmn-k7kl-r');
        else if (path === '/release/1558/' || path === '/release/1558') navigate('/news/y9fd1wk2d0kw');
        else if (path === '/release/1571/' || path === '/release/1571') navigate('/news/3310-leyf');
        else if (path === '/release/1591/' || path === '/release/1591') navigate('/news/5lfb151o7cwo');
        else if (path === '/release/1602/' || path === '/release/1602') navigate('/news/ykzy7evrumy');
        else if (path === '/release/1616/' || path === '/release/1616') navigate('/news/03az0b496c');
        else if (path === '/release/1620/' || path === '/release/1620') navigate('/news/b1pfg4ine6t');
        else if (path === '/release/1646/' || path === '/release/1646') navigate('/news/ttgkfgd6gqrv');
        else if (path === '/release/1696/' || path === '/release/1696') navigate('/news/ijmkzkv6x');
        else if (path === '/release/1718/' || path === '/release/1718') navigate('/news/ddibnr4cdju4');
        else if (path === '/release/1722/' || path === '/release/1722') navigate('/news/4ozdq0q9pz');
        else if (path === '/release/1736/' || path === '/release/1736') navigate('/news/aqvet3q03uhw');
        else if (path === '/release/1748/' || path === '/release/1748') navigate('/news/mw2n1y06s');
        else if (path === '/release/1756/' || path === '/release/1756') navigate('/news/wrmv4l5sdf');
        else if (path === '/release/179/' || path === '/release/179')   navigate('/news/udf5k17wp');
        else if (path === '/release/1817/' || path === '/release/1817') navigate('/news/heeov8hquo');
        else if (path === '/release/1825/' || path === '/release/1825') navigate('/news/e44g74q0lpn');
        else if (path === '/release/1847/' || path === '/release/1847') navigate('/news/dz60qqbitnr');
        else if (path === '/release/1877/' || path === '/release/1877') navigate('/news/3xsaina9w');
        else if (path === '/release/1883/' || path === '/release/1883') navigate('/news/n5rceubhq');
        else if (path === '/release/1912/' || path === '/release/1912') navigate('/news/wlsm8y00j6');
        else if (path === '/release/193/' || path === '/release/193')   navigate('/news/ymf3461zm');
        else if (path === '/release/1933/' || path === '/release/1933') navigate('/news/sd0n767k096b');
        else if (path === '/release/1973/' || path === '/release/1973') navigate('/news/2uiyuzfqsf');
        else if (path === '/release/1981/' || path === '/release/1981') navigate('/news/h0izujbdjfm');
        else if (path === '/release/1999/' || path === '/release/1999') navigate('/news/3mrj6r4ewn98');
        else if (path === '/release/2018/' || path === '/release/2018') navigate('/news/0r6x6cv7ktb');
        else if (path === '/release/2027/' || path === '/release/2027') navigate('/news/s65fbslsr0');
        else if (path === '/media/2447/' || path === '/media/2447')     navigate('/news/0blm7f30ae');
        else if (path === '/news/2441/' || path === '/news/2441')       navigate('/news/bk5ewo1hqio');
        else if (path === '/news/2435/' || path === '/news/2435')       navigate('/news/9fg6-9tnh0x');
        else if (path === '/news/2431/' || path === '/news/2431')       navigate('/news/tnuuqh9bg');
        else if (path === '/news/2428/' || path === '/news/2428')       navigate('/news/yl4y90i2zi');
        else if (path === '/news/2419/' || path === '/news/2419')       navigate('/news/rtlgp5f0z7i9');
        else if (path === '/release/2409/' || path === '/release/2409') navigate('/news/nulabdklow7f');
        else if (path === '/release/2400/' || path === '/release/2400') navigate('/news/mf-jee-tvo3');
        else if (path === '/news/2393/' || path === '/news/2393')       navigate('/news/8buzbxjy-c');
        else if (path === '/news/2386/' || path === '/news/2386')       navigate('/news/j3es0h00ihh2');
        else if (path === '/news/2366/' || path === '/news/2366')       navigate('/news/7y01v1047');
        else if (path === '/media/2378/' || path === '/media/2378')     navigate('/news/i4k300p0lqh5');
        else if (path === '/release/2337/' || path === '/release/2337') navigate('/news/7tlqqso6zs36');
        else if (path === '/media/2329/' || path === '/media/2329')     navigate('/news/wyltnx8b9b');
        else if (path === '/release/2324/' || path === '/release/2324') navigate('/news/zavp0hxx-os');
        else if (path === '/release/2317/' || path === '/release/2317') navigate('/news/ev6l02gxl');
        else if (path === '/news/2310/' || path === '/news/2310')       navigate('/news/zk5d3brjpf');
        else if (path === '/news/2301/' || path === '/news/2301')       navigate('/news/8z-z4vs7bi7');
        else if (path === '/release/2289/' || path === '/release/2289') navigate('/news/c1sjt2s9zaa');
        else if (path === '/release/2277/' || path === '/release/2277') navigate('/news/rx-lz9t7f3');
        else if (path === '/release/2268/' || path === '/release/2268') navigate('/news/b6l47b4jpv0h');
        else if (path === '/イベント/2250/' || path === '/イベント/2250')  navigate('/news/tbm8di-o3nzm');
        else if (path === '/release/2230/' || path === '/release/2230') navigate('/news/5v7izq9f941e');
        else if (path === '/release/2218/' || path === '/release/2218') navigate('/news/6e7cppjny6ix');
        else if (path === '/release/2204/' || path === '/release/2204') navigate('/news/q9didahso94');
        else if (path === '/release/2193/' || path === '/release/2193') navigate('/news/mzk9d2tar5ej');
        else if (path === '/release/2175/' || path === '/release/2175') navigate('/news/836l987qn');
        else if (path === '/release/2178/' || path === '/release/2178') navigate('/news/lne0kxbl8g9');
        else if (path === '/release/2169/' || path === '/release/2169') navigate('/news/qy0xjg025usd');
        else if (path === '/release/2096/' || path === '/release/2096') navigate('/news/h1llf7px0ey');
        else if (path === '/イベント/2063/' || path === '/イベント/2063')  navigate('/news/cdgz1p7s3f');
        else if (path === '/release/2036/' || path === '/release/2036') navigate('/news/tcbau9pc3734');
        else navigate('/')
    }, [path]);
    return null;
};